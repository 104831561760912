var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "px-1",
      staticStyle: { width: "600px", "min-height": "640px" }
    },
    [
      _vm.error
        ? _c(
            "v-alert",
            {
              attrs: {
                prominent: "",
                color: "error ligthen-2",
                border: "top",
                type: "error",
                dark: "",
                dismissible: ""
              }
            },
            [_vm._v(" " + _vm._s(_vm.error) + " ")]
          )
        : _vm._e(),
      _vm.viewState === "callActive"
        ? _c(
            "v-card",
            {
              staticClass: "d-flex align-center justify-center",
              attrs: { height: "544" }
            },
            [
              _c("call-active", {
                attrs: { phoneNumber: "(503) 989-2539", info: _vm.info },
                on: {
                  "show:info": function($event) {
                    return _vm.$emit("show:info", $event, _vm.info)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.viewState === "disposition"
        ? _c(
            "div",
            [
              _c("call-disposition", {
                on: {
                  disposition: function($event) {
                    _vm.disposition = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.viewState === "dialReady"
        ? _c(
            "div",
            [
              _c("video", { attrs: { id: "remoteVideo", hidden: "hidden" } }),
              _c("video", {
                attrs: {
                  id: "localVideo",
                  hidden: "hidden",
                  muted: "muted",
                  playsinline: "",
                  autoplay: ""
                },
                domProps: { muted: true }
              }),
              _c(
                "v-tabs",
                {
                  attrs: { centered: "", "icons-and-text": "" },
                  on: { change: _vm.tabChange },
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _vm._l(_vm.tabs, function(tab, index) {
                    return [
                      _c(
                        "v-tab",
                        { key: index },
                        [
                          _c("span", { staticClass: "text-caption" }, [
                            _vm._v(_vm._s(tab.label))
                          ]),
                          _c(
                            "v-icon",
                            {
                              staticClass: "secondary--text",
                              attrs: { large: "" }
                            },
                            [_vm._v(_vm._s(tab.icon))]
                          )
                        ],
                        1
                      )
                    ]
                  })
                ],
                2
              ),
              _vm.tab !== 2
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column justify-start align-start mt-2"
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { width: "100%" } },
                        [
                          _c("psi-form-text-field", {
                            ref: "phone",
                            attrs: {
                              id: _vm.$uuid.v4(),
                              "hide-details": "",
                              name: "phone",
                              clearable: "",
                              label: "Number to call",
                              placeholder: "Number to call",
                              icon: _vm.$config("icons.phone"),
                              mask: "(###)-###-####",
                              rules: [
                                function(v) {
                                  return (
                                    v == null ||
                                    v.length == 0 ||
                                    v.length == 14 ||
                                    "Phone number is not valid"
                                  )
                                }
                              ]
                            },
                            on: {
                              input: _vm.updatePhoneNumber,
                              "click:clear": _vm.clearPhoneNumber
                            },
                            model: {
                              value: _vm.phoneNumber,
                              callback: function($$v) {
                                _vm.phoneNumber = $$v
                              },
                              expression: "phoneNumber"
                            }
                          })
                        ],
                        1
                      ),
                      Object.keys(_vm.extensionInfo).length
                        ? _c(
                            "h4",
                            { staticClass: "mt-2 font-weight-regular" },
                            [_vm._v(" Call from (503) 989-2539 ")]
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _c(
                "v-tabs-items",
                {
                  staticClass: "mt-2",
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: "",
                            height: _vm.tabItemHeight,
                            color: "primary",
                            tile: ""
                          }
                        },
                        [
                          _c("phone-contacts", {
                            ref: "contacts",
                            attrs: { contacts: _vm.contacts },
                            on: { "update:phone": _vm.setPhone }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: "",
                            height: _vm.tabItemHeight,
                            color: "secondary",
                            tile: ""
                          }
                        },
                        [
                          _c("call-history", {
                            on: { "update:phone": _vm.setPhone }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: "",
                            height: _vm.tabItemHeight + 100,
                            color: "accent",
                            tile: ""
                          }
                        },
                        [_c("phone-settings")],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      ([0, 1].includes(_vm.tab) || _vm.callActive) && !_vm.disposition
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-column justify-center align-center mt-2 mb-2"
            },
            [
              _c("call-control", {
                attrs: {
                  phoneNumber: _vm.phoneNumberRaw,
                  numberValid: _vm.numberValid
                },
                on: { hangup: _vm.hangup }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }