<template>
    <div style="width: 600px; min-height: 640px" class="px-1">
        <v-alert
            v-if="error"
            prominent
            color="error ligthen-2"
            border="top"
            type="error"
            dark
            dismissible
        >
            {{ error }}
        </v-alert>
        <v-card
            v-if="viewState === 'callActive'"
            class="d-flex align-center justify-center"
            height="544"
        >
            <call-active
                phoneNumber="(503) 989-2539"
                :info="info"
                @show:info="$emit('show:info', $event, info)"
            ></call-active>
        </v-card>
        <div v-if="viewState === 'disposition'">
            <call-disposition
                @disposition="disposition = false"
            ></call-disposition>
        </div>

        <div v-if="viewState === 'dialReady'">
            <!-- RC SDK Required DOM Elements -->
            <video id="remoteVideo" hidden="hidden"></video>
            <video
                id="localVideo"
                hidden="hidden"
                muted="muted"
                playsinline
                autoplay
            ></video>
            <v-tabs v-model="tab" centered icons-and-text @change="tabChange">
                <template v-for="(tab, index) in tabs">
                    <v-tab :key="index">
                        <span class="text-caption">{{ tab.label }}</span>
                        <v-icon large class="secondary--text">{{
                            tab.icon
                        }}</v-icon>
                    </v-tab>
                </template>
            </v-tabs>
            <div
                class="d-flex flex-column justify-start align-start mt-2"
                v-if="tab !== 2"
            >
                <div style="width: 100%">
                    <psi-form-text-field
                        :id="$uuid.v4()"
                        hide-details
                        ref="phone"
                        name="phone"
                        clearable
                        v-model="phoneNumber"
                        label="Number to call"
                        placeholder="Number to call"
                        :icon="$config('icons.phone')"
                        mask="(###)-###-####"
                        @input="updatePhoneNumber"
                        @click:clear="clearPhoneNumber"
                        :rules="[
                            (v) =>
                                v == null ||
                                v.length == 0 ||
                                v.length == 14 ||
                                'Phone number is not valid',
                        ]"
                    ></psi-form-text-field>
                </div>
                <h4
                    class="mt-2 font-weight-regular"
                    v-if="Object.keys(extensionInfo).length"
                >
                    <!-- TODO: Pull From Extension Info -->
                    Call from (503) 989-2539
                </h4>
            </div>

            <v-tabs-items v-model="tab" class="mt-2">
                <v-tab-item>
                    <v-card flat :height="tabItemHeight" color="primary" tile>
                        <phone-contacts
                            ref="contacts"
                            :contacts="contacts"
                            @update:phone="setPhone"
                        ></phone-contacts>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat :height="tabItemHeight" color="secondary" tile>
                        <call-history @update:phone="setPhone"></call-history>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card
                        flat
                        :height="tabItemHeight + 100"
                        color="accent"
                        tile
                    >
                        <phone-settings></phone-settings>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <div
            class="d-flex flex-column justify-center align-center mt-2 mb-2"
            v-if="([0, 1].includes(tab) || callActive) && !disposition"
        >
            <call-control
                :phoneNumber="phoneNumberRaw"
                :numberValid="numberValid"
                @hangup="hangup"
            ></call-control>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "web-phone",
    components: {
        PhoneContacts: () => import("./PhoneContacts"),
        CallHistory: () => import("./CallHistory"),
        PhoneSettings: () => import("./PhoneSettings"),
        CallControl: () => import("./CallControl"),
        CallActive: () => import("./CallActive"),
        CallDisposition: () => import("./CallDisposition"),
    },
    props: {
        contacts: {
            type: Array,
            required: true,
        },
        applicant: {
            type: Object,
            required: true,
        },
        order_id: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            tab: 0,
            tabItemHeight: 400,
            phoneNumber: "",
            numberValid: false,
            disposition: false,
            showInfo: true,
            info: {
                order_id: this.order_id,
                items: [],
                component: "screening-rental-history",
                type: "",
            },
            tabs: [
                {
                    label: "Contacts",
                    component: "phone-contacts",
                    params: {
                        contacts: this.contacts,
                    },
                    icon: "mdi-account-multiple",
                },

                {
                    label: "History",
                    component: "call-history",
                    params: {},
                    icon: "mdi-history",
                },
                {
                    label: "Settings",
                    component: "phone-settings",
                    params: {},
                    icon: "mdi-cog",
                },
            ],
        };
    },
    computed: {
        ...mapGetters("Calls", [
            "history",
            "extensionInfo",
            "initialized",
            "callActive",
            "extensionInfo",
            "activeCallInfo",
            "contact",
        ]),
        ...mapGetters(["error"]),
        ...mapGetters("Users", ["user"]),
        phoneNumberRaw() {
            return this.phoneNumber
                ? this.phoneNumber.replace(/[^\d]/g, "")
                : "";
        },
        viewState() {
            const state = {
                initializing: !this.initialized,
                disposition: this.disposition,
                callActive: this.initialized && this.callActive,
                dialReady:
                    this.initialized && !this.callActive && !this.disposition,
            };
            return Object.keys(state)
                .filter((key) => state[key])
                .shift();
        },
    },
    watch: {
        contact: {
            immediate: true,
            handler(contact) {
                this.selectContact(contact);
            },
        },
        user: {
            immediate: true,
            handler(user) {
                // once we have a user, we can initialize the web phone
                if (Object.keys(user).length) {
                    // We need the video elements from the template to establish audio connections
                    const media = {
                        remote: document.getElementById("remoteVideo"),
                        local: document.getElementById("localVideo"),
                    };
                    this.initialize({ user, media });
                }
            },
        },
    },
    async created() {},
    async mounted() {
        this.fetchAudioDevices();
    },
    methods: {
        ...mapActions("Calls", [
            "initialize",
            "fetchCallHistory",
            "fetchAudioDevices",
        ]),

        hangup() {
            this.disposition = true;
            this.phoneNumber = "";
            this.$emit("show:info", false);
        },
        setPhone(value) {
            this.phoneNumber = value;
            this.updatePhoneNumber();
        },
        updatePhoneNumber() {
            if (Object.keys(this.$refs).includes("phone")) {
                this.$refs.phone.$refs.phone.validate();
                this.numberValid =
                    this.$refs.phone.$refs.phone.valid &&
                    this.phoneNumberRaw.length > 0;
            }
        },
        clearPhoneNumber() {
            this.updatePhoneNumber();
            this.$refs.contacts.clearContact();
        },
        tabChange(event) {
            // We want the latest call history so refresh to grab any calls made
            if (event === 1) {
                // call history
                this.fetchCallHistory();
            }
        },
        selectContact(contact) {
            if (contact !== null && Object.keys(contact).length > 0) {
                // Go through applicant addresses, employers and see if contact is associated
                if (this.$_.has(this.applicant, "addresses")) {
                    this.applicant.addresses.forEach((item) => {
                        this.$_.get(item, "contacts", []).forEach((elem) => {
                            if (elem.id === contact.id) {
                                this.info.items = [item];
                                this.info.type = "rental";
                                this.info.component =
                                    "screening-rental-history";
                            }
                        });
                    });
                }
                if (this.$_.has(this.applicant, "employers")) {
                    this.applicant.employers.forEach((item) => {
                        this.$_.get(item, "contacts", []).forEach((elem) => {
                            if (elem.id === contact.id) {
                                this.info.items = [item];
                                this.info.type = "employment";
                                this.info.component =
                                    "screening-employment-history";
                            }
                        });
                    });
                }
            }
        },
    },
};
</script>

<style scoped>
</style>